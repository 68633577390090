import { GetStaticPaths, GetStaticProps } from 'next';
import Layout from 'src/Layout';
import { getPageProps } from 'src/data';

import { RouteContextProvider } from 'src/hooks/useRouteContext';

import { RouteHandlerReturn } from 'src/types/model';

import { deleteNextjsCachedFiles } from 'lib/deleteNextjsCachedFiles';

import ErrorBoundary from 'components/BaseHelpers/ErrorBoundary';

const Page = (routeData: RouteHandlerReturn): JSX.Element => {
  return (
    <ErrorBoundary>
      <RouteContextProvider routeData={routeData.props}>
        <Layout routeData={routeData} />
      </RouteContextProvider>
    </ErrorBoundary>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  const props = await getPageProps(context);

  if (props.error?.renderErrorPage) {
    if (props.error.info instanceof Error) {
      throw props.error.info;
    } else {
      throw new Error(stringifyErrorInfo(props.error.info));
    }
  }

  // Next.js will attempt to re-generate the page:
  // - When a request comes in
  // - At most once every 30 seconds
  const revalidate = parseInt(process.env.REVALIDATE_INTERVAL || '30', 10); // In seconds

  // Check if we have a redirect (e.g. custom error page)
  if (props?.redirect) {
    await deleteNextjsCachedFiles(context);
    return {
      revalidate,
      redirect: props.redirect,
    };
  }

  if (props?.notFound) {
    await deleteNextjsCachedFiles(context);
  }

  return {
    // NextJS cannot serialize `undefined` values. Therefore we are
    // stringifying and then parsing the props to get rid of the `undefined` value
    props: JSON.parse(JSON.stringify(props)),
    revalidate,
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default Page;

const stringifyErrorInfo = (info: unknown): string => {
  if (typeof info === 'string') {
    return info;
  } else if (info instanceof Error) {
    return [info.name, info.message, info.cause, info.stack].join('\n');
  } else {
    try {
      return JSON.stringify(info);
    } catch {
      return '[[...path]].tsx - unable to stringify error info';
    }
  }
};
